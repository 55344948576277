import React, { useState} from "react";
/* import Template from "../assets/images/Template1.svg"; */

const Prices = () => {
    return (
        <React.Fragment>
            <p>Prices ok!</p>
        </React.Fragment>

    )

}

export default Prices;