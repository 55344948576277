import React, { useState} from "react";
/* import Template from "../assets/images/Template1.svg"; */

const Features = () => {
    return (
        <React.Fragment>
            <p>Features ok!</p>
        </React.Fragment>

    )

}

export default Features;